<template>
  <div class="col-12 table-responsive">
    <div class="card card-body">
      <table class="table table-hover table-bordered">
        <thead>
          <th>المستخدم</th>
          <th>التاريخ</th>
          <th>المبلغ</th>
          <th>عدد الطلبات</th>
          <th>النوع</th>
          <th>الحالة</th>
          <th>خيارات</th>
        </thead>
        <tbody>
          <tr v-for="order in orders" :key="order._id">
            <td>
              <span
                class="text-success"
                v-if="users[order.user_id]"
                @click="$router.push('/users#' + order.user_id)"
              >
                {{ users[order.user_id].name }}
              </span>
            </td>
            <td>
              {{ order.date }}
            </td>
            <td>
              {{ order.amount }}
            </td>
            <td>
              {{ order.orders_ids.length }}
            </td>
            <td>
              {{ order.type == "subscribe" ? "اشتراك" : "سحب" }}
            </td>
            <td>
              <span
                v-if="order.status == 0"
                style="border-radius: 0px"
                class="btn btn-sm btn-warning"
                >في الانتظار</span
              >
              <span
                v-if="order.status == 1"
                style="border-radius: 0px"
                class="btn btn-sm btn-success"
                >تم بنجاح</span
              >
              <span
                v-if="order.status == 2"
                style="border-radius: 0px"
                class="btn btn-sm btn-danger"
                >مرفوض</span
              >
            </td>
            <td>
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="خيارات"
                variant="primary"
                size="sm"
              >
                <b-dropdown-item
                  @click="alerta(order.details, order.admin_details)"
                >
                  <i class="fa fa-list"></i>
                  التفاصيل
                </b-dropdown-item>
                <b-dropdown-item @click="accept(order)">
                  <i class="fa fa-check text-success"></i>
                  تم بنجاح
                </b-dropdown-item>
                <b-dropdown-item @click="reject(order)">
                  <i class="fa fa-times text-danger"></i>
                  رفض الطلب
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      users: {},
      user: JSON.parse(localStorage.getItem("user")),
      orders: [],
    };
  },
  created() {
    var g = this;
    g.loading = true;
    $.post(api + "/admin/users/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      r.response.forEach((element) => {
        g.users[element._id] = element;
      });
      $.post(api + "/admin/users/resellers-orders", {
        jwt: g.user.jwt,
      })
        .then(function (r) {
          g.loading = false;
          g.orders = r.response;
        })
        .catch(function () {
          alert("error server or internet");
        });
    });
  },
  methods: {
    alerta(a, b) {
      alert(`المستخدم:\n${a}\n\nالادمن:\n ${b}`);
    },
    accept(order) {
      var g = this;
      var p = prompt("ملاحظاتك", order.admin_details);
      if (p) {
        if (p != "") {
          $.post(api + "/admin/users/resellers-accept", {
            jwt: g.user.jwt,
            order_id: order._id,
            details: p,
          })
            .then(function (r) {
              location.reload();
            })
            .catch(function () {
              alert("error server or internet");
            });
        }
      }
    },
    reject(order) {
      var g = this;
      var p = prompt("ملاحظاتك", order.admin_details);
      if (p) {
        if (p != "") {
          $.post(api + "/admin/users/resellers-reject", {
            jwt: g.user.jwt,
            order_id: order._id,
            details: p,
          })
            .then(function (r) {
              location.reload();
            })
            .catch(function () {
              alert("error server or internet");
            });
        }
      }
    },
  },
};
</script>

<style>
</style>